import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Mike Shecket | Aspiring Developer', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: "Portfolio of Mike Shecket's coding projects", // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: "Hi, I'm",
  name: 'Mike Shecket',
  subtitle: "and I'm an aspiring software developer.",
  cta: 'Check me out',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.png',
  paragraphOne: "I've been an educator for over twenty years and I'm planning on a career change to software development.",
  paragraphTwo: "I'm looking to get my foot in the door by building my skills through self-study and creating projects.",
  paragraphThree: "I've focused so far on front-end development with React, but I've had experience with other languages and frameworks and I'm willing and able to expand my horizons.",
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'mortgagecalculator.png',
    title: 'Mortgage Calculator',
    info: 'I used Test Driven Design to develop this simple mortgage calculator, which uses React to dynamically resize icons to based on the input amounts. After initially developing it, I decided to use Tailwind CSS to restyle it in order to make it more responsive and mobile-friendly.',
    info2: '',
    url: 'https://mortgagecalculator.mikeshecket.com',
    repo: 'https://github.com/mshecket/mortgage-calculator', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'drummachine.png',
    title: 'Drum Machine',
    info: "I completed this project as part of freeCodeCamp's Front End Libraries Certification. It uses React to implement a simple drum pad with nine samples that can be triggered with HTML buttons or keypresses.",
    info2: '',
    url: 'https://drummachine.mikeshecket.com',
    repo: 'https://github.com/mshecket/drum-machine', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'pomodoro.png',
    title: 'Pomodoro Clock',
    info: "This project was also completed as part of freeCodeCamp's Front End Libraries Certification. I implemented this timer with React which can be used to follow the Pomodoro Technique, a time management method that breaks down work into short periods with breaks in between.",
    info2: '',
    url: 'https://pomodoroclock.mikeshecket.com',
    repo: 'https://github.com/mshecket/pomodoro-clock', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Want to hire me?',
  btn: 'Yes, please',
  email: 'mike@mikeshecket.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/mshecket/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/mshecket/',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
